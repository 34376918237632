<template>
  <div v-if="items.length !== 0" ref="listbox">
    <div v-if="title" class="px-24" @mousedown.prevent="">
      <div class="flex items-center gap-8 pb-12 pt-24">
        <label
          class="text-static-default-low body-2-bold grow"
          for="search-bar-dropdown"
        >
          {{ title }}
        </label>
        <button
          v-if="withClear"
          class="text-action-default-low body-2 cursor-pointer"
          @click="cleanHandler"
        >
          {{ i18n(translations.clear) }}
        </button>
      </div>
    </div>
    <RevList
      id="search-bar-dropdown"
      class="divide-y-0"
      :has-external-borders="false"
      role="listbox"
    >
      <RevListItemInteractive
        v-for="(item, index) in items"
        :key="index"
        :aria-selected="selectedIndex === index"
        data-qa="search-bar-dropdown-item"
        data-test="search-bar-dropdown-item"
        role="option"
        tabindex="-1"
        @mousedown="() => clickHandler(index)"
      >
        <template #label>
          <!-- eslint-disable vue/no-v-html -->
          <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
          <span
            v-if="item?.highlightedTitle"
            class="body-1-bold flex-1 truncate"
            v-html="item.highlightedTitle"
          />
          <!-- eslint-enable -->
          <span v-else class="flex-1 truncate">
            {{ item.title }}
          </span>
        </template>
      </RevListItemInteractive>
    </RevList>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import type { AlgoliaAPISearchResultHitHighlighted } from '@backmarket/http-api/src/api-specs-search-reco/search/searchAlgolia'
import type { SearchPopularHit } from '@backmarket/http-api/src/api-specs-search-reco/search/searchPopular'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'

import translations from './Listbox.translations'

const props = withDefaults(
  defineProps<{
    items: AlgoliaAPISearchResultHitHighlighted[] | SearchPopularHit[]
    name: string
    selectedIndex: number
    title?: string
    withClear?: boolean
  }>(),
  {
    title: undefined,
    withClear: false,
  },
)

const i18n = useI18n()
const listbox = ref<HTMLInputElement | null>(null)

const emit = defineEmits(['clear', 'select'])

function clickHandler(index: number) {
  emit('select', index, props.name)
}

function cleanHandler() {
  emit('clear', props.name)
}

watch(
  () => props.selectedIndex,
  (newSelectedIndex) => {
    const item =
      newSelectedIndex === -1
        ? listbox.value?.querySelector('[role = "option"]')
        : listbox.value?.querySelector('[aria-selected = "true"]')

    if (item) {
      item.scrollIntoView({ block: 'center' })
    }
  },
)
</script>
