<template>
  <RevBackdrop
    v-if="isMenuActive"
    class="z-10 hidden backdrop-blur-2xl lg:block"
  />
  <header
    id="header"
    class="bg-surface-default-low sticky top-0 z-10 transition-all duration-200"
    :class="{ '-translate-y-full': headerIsHidden }"
  >
    <HeaderBar />
    <NavBar />
  </header>
</template>

<script setup lang="ts">
import { RevBackdrop } from '@ds/components/Backdrop'

import { useHideOnScroll } from '../composables/useHideOnScroll'
import { useNavigation } from '../composables/useNavigation'

import HeaderBar from './HeaderBar.vue'
import NavBar from './NavBar.vue'

const { hidden: headerIsHidden } = useHideOnScroll()

const { isMenuActive, fetchMenuItems } = useNavigation()
await fetchMenuItems()
</script>
