<template>
  <section class="flex-1">
    <ul class="mb-16">
      <li class="px-24 py-20">
        <NavBurgerMenuItem
          :color="TRADE_IN.color"
          :icon="TRADE_IN.icon"
          :title="TRADE_IN.title"
          :url="TRADE_IN.url"
        />
      </li>
    </ul>

    <div class="body-2 px-24">{{ i18n(translations.shop) }}</div>

    <ul>
      <li class="px-24 py-20">
        <NavBurgerMenuItem
          :color="GOOD_DEALS.color"
          :icon="GOOD_DEALS.icon"
          :title="GOOD_DEALS.title"
          :url="GOOD_DEALS.url"
        />
      </li>
      <li v-for="item in items" :key="item.id">
        <NavBurgerMenuUniverse
          :id="item.id"
          :image="item.image"
          :title="item.title"
        >
          <Header :color="item.color" :title="item.title" />
          <section class="overflow-auto pb-32">
            <Services :universe="item.title" />
            <div class="flex flex-col gap-12">
              <aside class="flex gap-8 px-24">
                <div class="body-2 flex-1">
                  {{ i18n(translations.categories) }}
                </div>
                <RevLink class="body-2-link" :to="item.url">{{
                  i18n(translations.see_all)
                }}</RevLink>
              </aside>
              <ul class="grid grid-cols-2 gap-x-16 gap-y-32 px-24">
                <li v-for="child in item.children" :key="child.id">
                  <NavBurgerMenuCategory
                    :image="child.image"
                    :title="child.title"
                    :url="child.url"
                  />
                </li>
              </ul>
            </div>
          </section>
        </NavBurgerMenuUniverse>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevLink } from '@ds/components/Link'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { IconSwap } from '@ds/icons/IconSwap'

import { useNavigation } from '~/scopes/navigation/composables/useNavigation'

import translations from '../NavMenu/NavMenu.translations'

import Header from './Header.vue'
import NavBurgerMenuCategory from './NavBurgerMenuCategory.vue'
import NavBurgerMenuItem from './NavBurgerMenuItem.vue'
import NavBurgerMenuUniverse from './NavBurgerMenuUniverse.vue'
import Services from './Services.vue'

const i18n = useI18n()
const locale = useI18nLocale()

const TRADE_IN = {
  title: i18n(translations.linkTradeIn),
  color: '#7B3DBD',
  icon: IconSwap,
  url: `/${locale}/buyback/home`,
}

const GOOD_DEALS = {
  title: i18n(translations.goodDeals),
  children: [],
  id: -1,
  color: '#9D3963',
  icon: IconSparkles,
  url: `/${locale}/e/good-deals`,
}

const { items } = useNavigation()
</script>
