<template>
  <section
    v-if="services.length > 0"
    class="flex flex-col px-24 pb-16 pt-24 lg:gap-16 lg:px-40 lg:py-24"
  >
    <h2 class="text-static-default-mid body-2">
      {{ i18n(translations.goodToKnow) }}
    </h2>
    <ul class="flex flex-col gap-12 py-12 lg:py-0">
      <li v-for="service in services" :key="service?.title">
        <NavMenuService
          v-if="service?.title && service?.description"
          class="w-full"
          :description="service.description"
          :icon="service.icon"
          :title="service.title"
        />
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { IconSwap } from '@ds/icons/IconSwap'

import translations from '../NavMenu/NavMenu.translations'
import NavMenuService from '../NavMenu/NavMenuService.vue'

const props = defineProps<{ universe: string }>()

const i18n = useI18n()

const t: Record<string, I18nDefinition | undefined> = translations
const getTranslation = (key: string, universe: string) => {
  const translation = t[`${key}_${universe.toLowerCase()}`]
  if (!translation) return undefined

  return i18n(translation)
}

const tradeIn = (universe: string) => {
  const title = getTranslation('tradeInTitle', universe)
  const description = getTranslation('tradeInDescription', universe)

  return title && description
    ? {
        title,
        description,
        icon: IconSwap,
      }
    : undefined
}
const mobilePlan = (universe: string) => {
  const title = getTranslation('mobilePlanTitle', universe)
  const description = getTranslation('mobilePlanDescription', universe)

  return title && description
    ? {
        title,
        description,
        icon: IconSparkles,
      }
    : undefined
}

const services = [tradeIn(props.universe), mobilePlan(props.universe)].flatMap(
  (item) => (item ? [item] : []),
)
</script>
