import { onBeforeUnmount, ref } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import { useNavigation } from './useNavigation'

export function useMegaMenu() {
  const {
    setActiveMenu,
    clearActiveMenu,
    activeMenu,
    isMenuActive,
    isActivePage,
  } = useNavigation()

  const { trackHoverOver } = useTracking()

  const timeout = ref()

  onBeforeUnmount(() => {
    clearTimeout(timeout.value)
  })
  function showMenu({
    id,
    title,
    position,
  }: {
    id: number
    title: string
    position: number
  }) {
    clearTimeout(timeout.value)
    timeout.value = setTimeout(() => {
      setActiveMenu(id)
      trackHoverOver({
        zone: 'header',
        name: 'department',
        value: title,
        page_type: 'product_list_page',
        position,
      })
    }, 150)
  }

  function hideMenu() {
    clearTimeout(timeout.value)
    timeout.value = setTimeout(() => {
      clearActiveMenu()
    }, 150)
  }

  return {
    showMenu,
    hideMenu,
    activeMenu,
    isMenuActive,
    isActivePage,
  }
}
