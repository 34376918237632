<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div
    class="flex h-full items-center transition-all duration-700 ease-in-out"
    role="none"
  >
    <RevLink
      :aria-expanded="String(activeMenu === id)"
      :aria-haspopup="String(hasPopup)"
      class="bold-hack text-action-default-low block h-full px-16 transition-all duration-700 ease-in-out hover:body-2-bold hover:no-underline"
      :class="[isActivePage(url) && !isMenuActive ? 'body-2-bold' : 'body-2']"
      role="menuitem"
      :style="{ color }"
      :title="title"
      :to="url"
      :underlined="false"
      @click="trackClick({ zone: 'header', name: 'department', value: title })"
    >
      <div
        class="flex h-full flex-nowrap items-center justify-center gap-4 border-t-transparent"
        :class="[
          hasPopup ? 'hover:border-b-static-default-hi hover:border-y-2' : '',
          isActivePage(url) && !isMenuActive
            ? 'border-b-static-default-hi border-y-2'
            : '',
        ]"
      >
        <component :is="icon" v-if="icon" />
        <span>{{ title }}</span>
      </div>
    </RevLink>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import { useNavigation } from '../../composables/useNavigation'

defineProps<{
  url: string
  id?: number
  color?: string
  title: string
  icon?: string | object
}>()

const slots = useSlots()
const hasPopup = computed(() => !!slots.default)

const { isMenuActive, activeMenu, isActivePage } = useNavigation()

const { trackClick } = useTracking()
</script>

<style scoped>
.bold-hack::after {
  display: block;
  content: attr(title);
  font-weight: 600;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  contain: content;
}
</style>
